<template>
  <div class="detail-counseling">
    <div class="mb-4">
      <a-descriptions bordered>
        <a-descriptions-item label="Student" :span="2">
          <p class="value mt-2">{{murid.nama || '-'}}</p>
        </a-descriptions-item>
        <a-descriptions-item label="Counseling Status" :span="1">
          <a-tag v-if="dataCounseling.status === 'closed'" color="red">closed</a-tag>
          <a-tag v-else color="green">ongoing</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Title" :span="2">
          <p class="value mt-2">{{dataCounseling.title || '-'}}</p>
        </a-descriptions-item>
        <a-descriptions-item label="Counseling Date" :span="1">
          <p class="value mt-2">{{dataCounseling.counseling_date ? moment(dataCounseling.counseling_date).format('DD-MM-YYYY') : '-'}}</p>
        </a-descriptions-item>
        <a-descriptions-item label="Category" :span="2">
          <p class="value mt-2">{{dataCounseling.kategori_bk ? dataCounseling.kategori_bk.name : '-'}}</p>
        </a-descriptions-item>
        <a-descriptions-item label="Concern Indicator" :span="1">
          <p class="value mt-2" :class="dataCounseling.concern_indicator < 3 ? 'text-success' : dataCounseling.concern_indicator < 5 ? 'text-warning' : 'text-danger'">{{dataCounseling.concern_indicator || 1}}/5</p>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="mt-4">
      <a-descriptions bordered title="Counseling Overview">
        <a-descriptions-item label="Concerns" :span="3">
          <div class="d-flex align-items-center mt-2" v-html="dataCounseling.concerns || '-'"></div>
        </a-descriptions-item>
        <a-descriptions-item label="Analysis" :span="3">
          <div class="d-flex align-items-center mt-2" v-html="dataCounseling.analysis || '-'"></div>
        </a-descriptions-item>
        <a-descriptions-item label="Solution" :span="3">
          <div class="d-flex align-items-center mt-2" v-html="dataCounseling.solution || '-'"></div>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <!-- <a-row class="mb-4">
      <a-col :xs="24" :md="12">
        <h3 class="type">Title</h3>
        <p class="value mt-2">{{dataCounseling.title || '-'}}</p>
      </a-col>
      <a-col :xs="24" :md="12">
        <h3 class="type">Counseling Date</h3>
        <p class="value mt-2">{{dataCounseling.counseling_date ? moment(dataCounseling.counseling_date).format('DD-MM-YYYY') : '-'}}</p>
      </a-col>
    </a-row>
    <a-row class="mb-4">
      <a-col :xs="24" :md="12">
        <h3 class="type">Concern Indicator</h3>
        <p class="value mt-2">Serious ({{dataCounseling.concern_indicator || 1}}/10)</p>
      </a-col>
      <a-col :xs="24" :md="12">
        <h3 class="type">Category</h3>
        <p class="value mt-2">{{dataCounseling.kategori_bk ? dataCounseling.kategori_bk.name : '-'}}</p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :xs="24" :md="24" :lg="8">
        <h3 class="type">Nama</h3>
        <p class="value mt-2">{{murid.nama || '-'}}</p>
      </a-col>
      <a-col :xs="24" :md="24" :lg="8" class="mt-4 mt-lg-0">
        <h3 class="type">Jenis Kelamin</h3>
        <p class="value mt-2">
          {{murid.jenis_kelamin || '-'}}
        </p>
      </a-col>
      <a-col :xs="24" :md="24" :lg="8">
        <h3 class="type">NIS</h3>
        <p class="value mt-2">{{murid.nis || '-'}}</p>
      </a-col>
    </a-row> -->
    <!-- <a-col :xs="24" :md="12" :lg="6" class="mt-4 mt-lg-0">
      <h3 class="type">Kelas</h3>
      <div class="d-flex align-items-center mt-2">
        <p class="value mr-2">
          VII-1
        </p>
      </div>
    </a-col> -->
  </div>
</template>

<script>
import moment from 'moment'
import { BKMarks } from '@/helpers/general'

export default {
  props: {
    dataCounseling: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      marks: BKMarks,
    }
  },
  computed: {
    murid() {
      return this.dataCounseling?.kelas_tahun_ajaran?.murid || {}
    },
  },
  methods: {
    moment,
  },
  watch: {
  },
}
</script>

<style lang="scss">
</style>
